.loading-screen {
  position: relative;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  transition: opacity 0.2s;
}

.loading-screen,
.loading-screen .loading {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-content: center;
  align-content: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.loading-screen .icon.icon-name-loading {
  width: 80px;
  height: 80px;
}

.icon-name-loading {
  position: relative;
}
.icon-size-m {
  /*--size-modifier: var(--size-modifier-m);*/
}
.icon {
  --foreground-color: var(--dark-color);
  --background-color: transparent;
  --size-modifier: var(--size-modifier-m);
  display: inline-block;
  width: calc(1rem * var(--size-modifier));
  height: calc(1rem * var(--size-modifier));
  color: var(--foreground-color);
  background-color: var(--background-color);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
}

.icon-name-loading:after {
  position: absolute;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin-top: -50%;
  margin-left: -50%;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-top-color: #000;
  border-radius: 50%;
  -webkit-animation: spinner 0.6s linear infinite;
  animation: spinner 0.6s linear infinite;
  content: "";
}

@keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }