@font-face {
  font-family: "NeueHaasGroteskDisplay-Regular";
  font-style: normal;
  src: url('../../assets/fonts/NeueHaasGroteskDisp.ttf') format("opentype");
}

@font-face {
  font-family: "NeueHaasGroteskDisplay-Bold";
  font-style: normal;
  src: url('../../assets/fonts/NeueHaasGroteskDisp Bold.ttf') format("opentype");
}

.app-wrap {
  max-width: 610px;
  margin: 80px auto;
}

img.logo {
  width: 184px;
  height: 38px;
}

.title {
  font-family: NeueHaasGroteskDisplay-Bold;
  font-size: 14px;
  letter-spacing: 0.8px;
  color: #424242;
  text-transform: uppercase;
  margin: 80px 0 30px 0;
}
